import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { signUpRequest } from "state/slices/account";
import { ErrorMessage, Field, Form, Formik } from "formik";
import produce from "immer";
import { toast } from "react-toastify";
import TextInput from "components/shared/forms/TextInput";
import Button from "components/shared/forms/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faUser, faBuilding } from "@fortawesome/free-regular-svg-icons";
import Switch from "react-switch";
import ThemedSelect from "components/shared/forms/ThemedSelect";
import {
  getAPIErrorsForFormik,
  convertModelArrayToSelectOptions,
  getOneLinerErrorMessage,
} from "utilities";
import "./styles.scss";
import { fetchAllEventCategoriesRequest } from "state/slices/events";
import AlternateNavBar from "components/shared/partials/AlternateNavBar";
import { convertCountryToOptions } from "../../../../lib/country";
import { GoogleAuthButton } from "../SocialAuths/GoogleAuthButton";
import { FacebookAuthButton } from "../SocialAuths/FacebookAuthButton";

const SwitchIcon = ({ title, className }) => (
  <div
    className={`d-flex flex-row justify-content-center align-items-center h-100 font-weight-normal text-gray-200 ${
      className || ""
    }`}
  >
    {title}
  </div>
);

const HandleIcon = ({ icon }) => (
  <div className="d-flex flex-row justify-content-center align-items-center text-white h-100 text-sm">
    <FontAwesomeIcon icon={icon} />
  </div>
);

const SignUp = ({ history }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };
  const eventCategories = useSelector((state) => state.events.categoriesData);
  const savedLocation = useSelector((state) => state.location);

  const countryOptions = convertCountryToOptions();
  const eventCategoryOptions = convertModelArrayToSelectOptions(
    eventCategories,
    "value",
    "type"
  );

  useLayoutEffect(() => {
    document.title = "Triple7Events - Sign Up";
  }, []);

  useEffect(() => {
    dispatch(fetchAllEventCategoriesRequest());
  }, []);

  const [state, setState] = useState({
    isSignUpLoading: false,
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    accountType: "",
    companyName: "",
    eventCategories: "",
    country: {
      label: savedLocation?.country,
      value: savedLocation?.country,
    },
    username: "",
    password1: "",
    password2: "",
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().label("First name").required(),
    lastName: yup.string().label("Last name").required(),
    accountType: yup.string().label("Account type"),
    eventCategories: yup.array().label("Preferred event categories"),
    country: yup.object().label("Country").required(),
    username: yup.string().email().label("Username").required(),
    password1: yup
      .string()
      .label("Password")
      .min("6")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/, " ")
      .required(),
    password2: yup
      .string()
      .label("Password confirmation")
      .oneOf([null, yup.ref("password1")], "Both passwords needs to be same")
      .required(),
  });

  const handleSignUp = async (formValues, formikHelpers) => {
    if (formValues.accountType && !formValues.companyName) {
      return toast.error("Please enter your company name");
    }
    const catValues = [];
    if (formValues.eventCategories && !!formValues.eventCategories.length) {
      formValues.eventCategories.forEach((element) => {
        catValues.push(`${element.value}`);
      });
    }
    const formPayload = {
      ...formValues,
      companyName: formValues.accountType ? formValues.companyName : "",
      accountType: formValues.accountType ? "org" : null,
      country: formValues.country.value,
      eventCategories: catValues.length ? catValues : undefined,
    };

    setState(produce((draft) => void (draft.isSignUpLoading = true)));

    const resultAction = await dispatch(signUpRequest(formPayload));

    if (signUpRequest.fulfilled.match(resultAction)) {
      localStorage.setItem("token", resultAction.payload.token);
      formikHelpers.resetForm();
      setState(produce((draft) => void (draft.isSignUpLoading = false)));
      history.replace(from);
    } else {
      formikHelpers.setErrors(getAPIErrorsForFormik(resultAction.payload));
      setState(produce((draft) => void (draft.isSignUpLoading = false)));

      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <Fragment>
      <AlternateNavBar />

      <section className="signup-page--form pt-space">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-6 col-xl-5 mx-auto mt-4">
              <div className="shadow rounded-lg p-4 mx-3 my-4">
                <h3 className="text-center font-weight-normal">Sign Up</h3>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSignUp}
                >
                  {({
                    handleSubmit,
                    isValid,
                    values,
                    setFieldValue,
                    errors,
                  }) => {
                    return (
                      <Form className="pt-4" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <Field
                            component={TextInput}
                            className="form-control form-control-lg font-weight-normal text-base"
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="First Name"
                            appendedComponent={() => (
                              <div className="input-group-text">
                                <span className="fas fa-envelope" />
                              </div>
                            )}
                          />
                        </div>

                        <div className="form-group">
                          <Field
                            component={TextInput}
                            className="form-control form-control-lg font-weight-normal text-base"
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Last Name"
                            appendedComponent={() => (
                              <div className="input-group-text">
                                <span className="fas fa-envelope" />
                              </div>
                            )}
                          />
                        </div>

                        <div className="form-group">
                          <div className="d-flex flex-row justify-content-between w-100">
                            <label htmlFor="small-radius-switch">
                              <span className="font-weight-normal text-gray-200">
                                Account Type
                              </span>
                              <Switch
                                checked={values.accountType}
                                onChange={(value) => {
                                  setFieldValue("accountType", value);
                                }}
                                handleDiameter={28}
                                offColor="#eeeeee"
                                onColor="#eeeeee"
                                offHandleColor="#96141d"
                                onHandleColor="#029d02"
                                height={30}
                                width={125}
                                borderRadius={6}
                                activeBoxShadow="none"
                                className="react-switch ml-2"
                                id="small-radius-switch"
                                uncheckedIcon={
                                  <SwitchIcon
                                    className="pr-5"
                                    title="Individual"
                                  />
                                }
                                checkedIcon={
                                  <SwitchIcon
                                    className="pl-5"
                                    title="Organization"
                                  />
                                }
                                uncheckedHandleIcon={
                                  <HandleIcon icon={faUser} />
                                }
                                checkedHandleIcon={
                                  <HandleIcon icon={faBuilding} />
                                }
                              />
                            </label>
                          </div>
                          <ErrorMessage
                            name="accountType"
                            component="div"
                            className="text-danger text-sm font-weight-normal pt-1"
                          />
                        </div>
                        {values.accountType && (
                          <>
                            <div className="form-group">
                              <Field
                                component={TextInput}
                                className="form-control form-control-lg font-weight-normal text-base"
                                type="text"
                                name="companyName"
                                id="companyName"
                                placeholder="Company Name"
                                appendedComponent={() => (
                                  <div className="input-group-text">
                                    <span className="fas fa-envelope" />
                                  </div>
                                )}
                              />
                            </div>
                          </>
                        )}
                        {!!eventCategoryOptions.length && (
                          <div className="form-group">
                            <Field
                              component={ThemedSelect}
                              name="eventCategories"
                              id="preferredEventCategories"
                              placeholder="Select preferred event categories..."
                              size="lg"
                              options={eventCategoryOptions}
                              isMulti={true}
                            />
                          </div>
                        )}

                        <div className="form-group">
                          <Field
                            component={ThemedSelect}
                            name="country"
                            id="country"
                            placeholder="Select country..."
                            size="lg"
                            options={countryOptions}
                          />
                        </div>

                        <div className="form-group">
                          <Field
                            component={TextInput}
                            className="form-control form-control-lg font-weight-normal text-base"
                            type="email"
                            name="username"
                            id="email"
                            placeholder="Email Address"
                            appendedComponent={() => (
                              <div className="input-group-text">
                                <span className="fas fa-envelope" />
                              </div>
                            )}
                          />
                        </div>

                        <div className="form-group">
                          <Field
                            component={TextInput}
                            className="form-control form-control-lg font-weight-normal text-base"
                            type="password"
                            name="password1"
                            id="password"
                            placeholder="Password"
                            appendedComponent={() => (
                              <div className="input-group-text">
                                <span className="fas fa-lock" />
                              </div>
                            )}
                          />
                          {errors.password1 && (
                            <p className="text-xs font-weight-medium text-accent">
                              Password must be at least 6 characters in length
                              and must contain at least 1 lowercase letter, 1
                              uppercase letter and 1 number
                            </p>
                          )}
                        </div>

                        <div className="form-group">
                          <Field
                            component={TextInput}
                            className="form-control form-control-lg font-weight-normal text-base"
                            type="password"
                            name="password2"
                            id="retypePassword"
                            placeholder="Retype Password"
                            appendedComponent={() => (
                              <div className="input-group-text">
                                <span className="fas fa-lock" />
                              </div>
                            )}
                          />
                        </div>

                        <div className="form-group">
                          <Button
                            type="submit"
                            className="btn-secondary btn-lg btn-block text-lg"
                            title="Sign Up"
                            loadingTitle="Signing up..."
                            loading={state.isSignUpLoading}
                            disabled={state.isSignUpLoading || !isValid}
                          />
                        </div>

                        <div className="form-group">
                          <div className="col-12 d-flex flex-row justify-content-center">
                            <span className="font-weight-normal text-gray-200">
                              Or sign up with
                            </span>
                          </div>

                          <GoogleAuthButton title={"Sign up with Google"} />
                          <FacebookAuthButton title={"Sign up with Facebook"} />
                        </div>

                        <div className="form-group">
                          <div className="text-center font-weight-normal">
                            Already registered?{" "}
                            <Link
                              to="/auth/login"
                              className="font-weight-bold"
                              href="#"
                            >
                              Login
                            </Link>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default SignUp;
