import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./styles.scss";
import { Icon } from "@iconify/react";

export const DashboardSidebar = ({ isOpen, toggleSidebar }) => {
  const [showSubLink1, setShowSubLink1] = useState(true);
  const [showSubLink2, setShowSubLink2] = useState(false);
  const [showSubLink3, setShowSubLink3] = useState(false);
  const [showSubLink4, setShowSubLink4] = useState(false);
  const [showSubLink5, setShowSubLink5] = useState(false);
  const [showSubLink6, setShowSubLink6] = useState(false);
  const [showSubLink7, setShowSubLink7] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"))?.detail;
  return (
    <div className="dashboard-sidebar-wrapper">
      <div
        onClick={toggleSidebar}
        className={
          isOpen ? "sidebar-overlay overlay-isOpen" : "sidebar-overlay"
        }
      >
        <div className="closeMenu">
          <Icon icon="ci:close-big" />
        </div>
      </div>
      <div
        id="sidebar-wrapper"
        className={isOpen ? "sidebar-toggle active" : "sidebar-toggle"}
      >
        <div className="sidebar-nav">
          <div className="space-out">
            <div>
              <ul className="pl-0 text-left">
                <li>
                  <div
                    onClick={() => {
                      setShowSubLink2(false);
                      setShowSubLink3(false);
                      setShowSubLink4(false);
                      setShowSubLink5(false);
                      setShowSubLink1(!showSubLink1);
                    }}
                    className="pl-3 parent-link"
                  >
                    Dashboard
                  </div>
                  <div className={`sub-links ${showSubLink1 ? "show" : ""}`}>
                    <NavLink
                      activeClassName="active"
                      to="/dashboard/booked"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Booked Events
                    </NavLink>

                    <NavLink
                      activeClassName="active"
                      to="/dashboard/saved"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Saved Events
                    </NavLink>

                    <NavLink
                      activeClassName="active"
                      to="/dashboard/live"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Live Events
                    </NavLink>
                    <NavLink
                      activeClassName="active"
                      to="/dashboard/past"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Past Events
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      setShowSubLink1(false);
                      setShowSubLink2(false);
                      setShowSubLink3(false);
                      setShowSubLink4(false);
                      setShowSubLink5(!showSubLink5);
                    }}
                    className="pl-3 parent-link"
                  >
                    My Account
                  </div>
                  <div className={`sub-links ${showSubLink5 ? "show" : ""}`}>
                    <NavLink
                      activeClassName="active"
                      to="/dashboard/accounts/update-profile"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Account Details
                    </NavLink>

                    <NavLink
                      activeClassName="active"
                      to="/dashboard/accounts/change-password"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Change Password
                    </NavLink>
                    <NavLink
                      activeClassName="active"
                      to="/dashboard/accounts/multi-users"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Multi User Access
                    </NavLink>
                    <NavLink
                      activeClassName="active"
                      to="/dashboard/accounts/close"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Close Account
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      setShowSubLink1(false);
                      setShowSubLink3(false);
                      setShowSubLink4(false);
                      setShowSubLink5(false);
                      setShowSubLink2(!showSubLink2);
                    }}
                    className="pl-3 parent-link"
                  >
                    My Events
                  </div>
                  <div className={`sub-links ${showSubLink2 ? "show" : ""}`}>
                    <NavLink
                      activeClassName="active"
                      to="/dashboard/published-events"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Published
                    </NavLink>

                    <NavLink
                      activeClassName="active"
                      to="/dashboard/unpublished-events"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Unpublished
                    </NavLink>
                    <NavLink
                      activeClassName="active"
                      to="/dashboard/tickets-transaction-history"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Tickets History
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      setShowSubLink1(false);
                      setShowSubLink2(false);
                      setShowSubLink4(false);
                      setShowSubLink5(false);
                      setShowSubLink3(!showSubLink3);
                    }}
                    className="pl-3 parent-link"
                  >
                    My Tickets
                  </div>
                  <div className={`sub-links ${showSubLink3 ? "show" : ""}`}>
                    <NavLink
                      activeClassName="active"
                      to="/dashboard/current-tickets"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Current Tickets
                    </NavLink>

                    <NavLink
                      activeClassName="active"
                      to="/dashboard/past-tickets"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Past Tickets
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      setShowSubLink1(false);
                      setShowSubLink2(false);
                      setShowSubLink3(false);
                      setShowSubLink5(false);
                      setShowSubLink4(!showSubLink4);
                    }}
                    className="pl-3 parent-link"
                  >
                    Attendee Registration Form
                  </div>
                  <div className={`sub-links ${showSubLink4 ? "show" : ""}`}>
                    <NavLink
                      activeClassName="active"
                      to="/dashboard/registration-form"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Registration Form
                    </NavLink>

                    <NavLink
                      activeClassName="active"
                      to="/dashboard/configure"
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Configure
                    </NavLink>
                  </div>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to="/dashboard/contact-list"
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Contact List
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    activeClassName="active"
                    to="/dashboard/event-gallery"
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Event gallery
                  </NavLink>
                </li>
                {user?.country === "NGA" && (
                  <>
                    <li>
                      <div
                        onClick={() => {
                          setShowSubLink1(false);
                          setShowSubLink2(false);
                          setShowSubLink3(false);
                          setShowSubLink4(false);
                          setShowSubLink5(false);
                          setShowSubLink6(!showSubLink6);
                        }}
                        className="pl-3 parent-link"
                      >
                        My Wallet
                      </div>
                      <div
                        className={`sub-links ${showSubLink6 ? "show" : ""}`}
                      >
                        <NavLink
                          exact
                          activeClassName="active"
                          to="/dashboard/wallet"
                          className="pl-3"
                          onClick={toggleSidebar}
                        >
                          Wallet Dashboard
                        </NavLink>

                        <NavLink
                          exact
                          activeClassName="active"
                          to="/dashboard/wallet/pin"
                          className="pl-3"
                          onClick={toggleSidebar}
                        >
                          Change Pin
                        </NavLink>
                        <NavLink
                          exact
                          activeClassName="active"
                          to="/dashboard/business-wallet"
                          className="pl-3"
                          onClick={toggleSidebar}
                        >
                          Business Wallet
                        </NavLink>
                      </div>
                    </li>

                    <li>
                      <NavLink
                        activeClassName="active"
                        to="/dashboard/stores"
                        className="pl-3"
                        onClick={toggleSidebar}
                      >
                        My Store
                      </NavLink>
                    </li>

                    <li>
                      <div
                        onClick={() => {
                          setShowSubLink1(false);
                          setShowSubLink2(false);
                          setShowSubLink3(false);
                          setShowSubLink4(false);
                          setShowSubLink5(false);
                          setShowSubLink6(false);
                          setShowSubLink7(!showSubLink7);
                        }}
                        className="pl-3 parent-link"
                      >
                        Fuel Station
                      </div>
                      <div
                        className={`sub-links ${showSubLink7 ? "show" : ""}`}
                      >
                        <NavLink
                          exact
                          activeClassName="active"
                          to="/dashboard/fuel-buyers"
                          className="pl-3"
                          onClick={toggleSidebar}
                        >
                          Buyer
                        </NavLink>

                        <NavLink
                          exact
                          activeClassName="active"
                          to="/dashboard/fuel-vendors"
                          className="pl-3"
                          onClick={toggleSidebar}
                        >
                          Vendor
                        </NavLink>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
