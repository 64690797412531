import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Store } from "services/network";
const token = localStorage.getItem("token");

const initialState = {
  storeLoading: false,
  storeError: null,
  data: {},
};

export const getAllStoresRequest = createAsyncThunk(
  "stores/allStores",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getAllStores();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createStoreRequest = createAsyncThunk(
  "stores/allStores",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.createStore(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchStoreDashboardRequest = createAsyncThunk(
  "stores/storeDash",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getStoreDash(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStoreUserRoleRequest = createAsyncThunk(
  "stores/storeUserRole",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getStoreUserRole(payload);
      localStorage.setItem("storeUserRole", JSON.stringify(response.data.role));
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllStoreCategoryRequest = createAsyncThunk(
  "stores/AllStoreCategory",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.AllStoreCategory();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStoreCategoryRequest = createAsyncThunk(
  "stores/getStoreCategory",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getStoreCategory(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editStoreCategoryRequest = createAsyncThunk(
  "stores/editStoreCategory",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.editStoreCategory(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const addStoreCategoryRequest = createAsyncThunk(
  "stores/addStoreCategory",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.addStoreCategory(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteStoreCategoryRequest = createAsyncThunk(
  "stores/deleteStoreCategory",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.deleteStoreCategory(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadDocumentRequest = createAsyncThunk(
  "stores/uploadDocument",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.uploadDocument(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStoreItemsRequest = createAsyncThunk(
  "stores/getStoreItems",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getStoreItems(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editStoreItemRequest = createAsyncThunk(
  "stores/editStoreItem",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.editStoreItem(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteStoreItemRequest = createAsyncThunk(
  "stores/deleteStoreItem",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.deleteStoreItem(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createItemRequest = createAsyncThunk(
  "stores/createItem",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.createItem(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editItemRequest = createAsyncThunk(
  "stores/editItem",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.editItem(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStoreUserListRequest = createAsyncThunk(
  "stores/StoreUserList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getStoreUserList(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const assignUserListRequest = createAsyncThunk(
  "stores/assignUserList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.assignUserList(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editStoreUserListRequest = createAsyncThunk(
  "stores/editStoreUserList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.editStoreUserList(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStoreRolesRequest = createAsyncThunk(
  "stores/getStoreRoles",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getStoreRoles();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createStoreRolesRequest = createAsyncThunk(
  "stores/createStoreRoles",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.createStoreRoles(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editStoreRolesRequest = createAsyncThunk(
  "stores/editStoreRoles",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.editStoreRoles(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStoreOrderRequest = createAsyncThunk(
  "stores/getStoreOrder",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getStoreOrder(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStoreOrdersPDFRequest = createAsyncThunk(
  "stores/getStoreOrdersPDF",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getStoreOrdersPDF(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStoreSalesRequest = createAsyncThunk(
  "stores/getStoreSales",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getStoreSales(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStoreDetailsRequest = createAsyncThunk(
  "stores/getStoreDetails",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getStoreDetails(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStorePageItemsRequest = createAsyncThunk(
  "stores/getStorePageItems",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getStorePageItems(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStorePageItemDetailsRequest = createAsyncThunk(
  "stores/getStorePageItemDetails",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getStorePageItemDetails(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const addItemToCartRequest = createAsyncThunk(
  "stores/addItemToCart",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.addItemToCart(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createNoAuthCartRequest = createAsyncThunk(
  "stores/createNoAuthCart",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.createNoAuthCart(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserCartRequest = createAsyncThunk(
  "stores/getUserCart",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getUserCart(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeItemFromCartRequest = createAsyncThunk(
  "stores/removeItemFromCart",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.removeItemFromCart(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCartVariantRequest = createAsyncThunk(
  "stores/updateCartVariant",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.updateCartVariant(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStoreTableRequest = createAsyncThunk(
  "stores/getStoreTable",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.getStoreTable(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createStoreTableRequest = createAsyncThunk(
  "stores/createStoreTable",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.createStoreTable(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteStoreTableRequest = createAsyncThunk(
  "stores/deleteStoreTable",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Store.deleteStoreTable(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);
const storesSlice = createSlice({
  name: "stores",
  initialState: initialState,
  reducers: {
    logoutStoreData: (state) => {
      state.data = initialState.data;
    },
    deleteCart: (state) => {
      delete state.data.noAuthCart;
      delete state.data.userCart;
    },
    updateReduxCartRequest: (state, action) => {
      if (token) {
        state.data = {
          ...state.data,
          userCart: action.payload,
        };
      } else {
        state.data = {
          ...state.data,
          noAuthCart: action.payload,
        };
      }
    },
  },
  extraReducers: {
    [fetchStoreDashboardRequest.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
    [getAllStoreCategoryRequest.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        allStoreCategories: action.payload.data,
      };
    },
    [getStoreOrdersPDFRequest.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        allOrders: action.payload.data,
      };
    },
    [getStoreDetailsRequest.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        storePageDetail: action.payload.data,
      };
    },
    [getUserCartRequest.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        userCart: action.payload.data,
      };
    },
    [createNoAuthCartRequest.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        noAuthCart: action.payload.cart,
      };
    },
    [addItemToCartRequest.fulfilled]: (state, action) => {
      if (!token) {
        state.data = {
          ...state.data,
          noAuthCart: action.payload.cart,
        };
      }
    },
  },
});

export const { logoutStoreData } = storesSlice.actions;
export const { deleteCart } = storesSlice.actions;
export const { updateReduxCartRequest } = storesSlice.actions;
export default storesSlice.reducer;
