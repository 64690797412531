import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import "./styles.scss";

export const FuelVendorSidebar = ({ isOpen, toggleSidebar }) => {
  const [showSubLink, setShowSubLink] = useState("");
  const history = useHistory();

  const { fuelVendorId } = useParams();

  if (!fuelVendorId) {
    history.push("/dashboard/fuel-vendors");
  }

  return (
    <div className="event-dash-sidebar-wrapper">
      <div
        onClick={toggleSidebar}
        className={
          isOpen ? "sidebar-overlay overlay-isOpen" : "sidebar-overlay"
        }
      >
        <div className="closeMenu">
          <Icon icon="ci:close-big" />
        </div>
      </div>
      <div
        id="sidebar-wrapper"
        className={isOpen ? "sidebar-toggle active" : "sidebar-toggle"}
      >
        <div className="sidebar-nav">
          <div className="space-out">
            <div>
              <ul className="pl-0 text-left">
                <li className="bg-gray-70">
                  <NavLink
                    to="/dashboard/fuel-vendors"
                    className="font-weight-bold pl-3 text-gray-300"
                  >
                    <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                    Back To
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    exact
                    to={`/dashboard/fuel-vendor/${fuelVendorId}`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/fuel-vendor/${fuelVendorId}/products`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Products
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/fuel-vendor/${fuelVendorId}/staffs`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Staffs
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/fuel-vendor/${fuelVendorId}/orders`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Orders
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/fuel-vendor/${fuelVendorId}/payout/transactions`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Payout Transactions
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
