import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@iconify/react";
import React from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import "./styles.scss";

export const FuelBuyerSidebar = ({ isOpen, toggleSidebar }) => {
  const history = useHistory();

  const { fuelBuyerId } = useParams();

  if (!fuelBuyerId) {
    history.push("/dashboard/fuel-buyers");
  }

  return (
    <div className="event-dash-sidebar-wrapper">
      <div
        onClick={toggleSidebar}
        className={
          isOpen ? "sidebar-overlay overlay-isOpen" : "sidebar-overlay"
        }
      >
        <div className="closeMenu">
          <Icon icon="ci:close-big" />
        </div>
      </div>
      <div
        id="sidebar-wrapper"
        className={isOpen ? "sidebar-toggle active" : "sidebar-toggle"}
      >
        <div className="sidebar-nav">
          <div className="space-out">
            <div>
              <ul className="pl-0 text-left">
                <li className="bg-gray-70">
                  <NavLink
                    to="/dashboard/fuel-buyers"
                    className="font-weight-bold pl-3 text-gray-300"
                  >
                    <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                    Back To
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    exact
                    to={`/dashboard/fuel-buyer/${fuelBuyerId}`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/fuel-buyer/${fuelBuyerId}/vehicles`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Vehicles
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/fuel-buyer/${fuelBuyerId}/drivers`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Drivers
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/fuel-buyer/${fuelBuyerId}/purchase`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Purchase Fuel
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/fuel-buyer/${fuelBuyerId}/orders`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Orders
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
