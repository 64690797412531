import React, { Fragment, useLayoutEffect, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { loginRequest } from "state/slices/account";
import { Field, Form, Formik } from "formik";
import produce from "immer";
import { toast } from "react-toastify";
import TextInput from "components/shared/forms/TextInput";
import Button from "components/shared/forms/Button";
import { getOneLinerErrorMessage } from "utilities";
import AlternateNavBar from "components/shared/partials/AlternateNavBar";
import "./styles.scss";
import { getUserProfileRequest } from "state/slices/users";
import queryString from "query-string";
import { GoogleAuthButton } from "../SocialAuths/GoogleAuthButton";
import { FacebookAuthButton } from "../SocialAuths/FacebookAuthButton";

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };
  const { userToken } = queryString.parse(useLocation().search);

  const token = localStorage.getItem("token");

  useLayoutEffect(() => {
    document.title = "Triple7Events - Login";
  }, []);

  const [state, setState] = useState({
    isLoginLoading: false,
  });

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    username: yup.string().label("Username").required(),
    password: yup.string().label("Password").required(),
  });

  const handleLogin = async (formValues, formikHelpers) => {
    setState(produce((draft) => void (draft.isLoginLoading = true)));

    const resultAction = await dispatch(loginRequest(formValues));

    if (loginRequest.fulfilled.match(resultAction)) {
      setState(produce((draft) => void (draft.isLoginLoading = false)));
      if (!resultAction.payload.admin) {
        localStorage.setItem("token", resultAction.payload.token);
        getProfile();
      } else {
        toast.error("You are an admin, pls login from the admin portal!");
      }
    } else {
      formikHelpers.setFieldValue("password", "", false);
      setState(produce((draft) => void (draft.isLoginLoading = false)));

      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  const getProfile = async () => {
    setState(produce((draft) => void (draft.isLoginLoading = true)));
    const resultAction = await dispatch(getUserProfileRequest());
    if (getUserProfileRequest.fulfilled.match(resultAction)) {
      history.push(from.pathname == "/" ? "/dashboard/booked" : from);
    } else {
      setState(produce((draft) => void (draft.isLoginLoading = false)));
      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  useEffect(() => {
    if (userToken || token) {
      if (userToken) {
        localStorage.setItem("token", userToken);
      }
      getProfile();
    }
  }, [userToken]);

  return (
    <Fragment>
      <AlternateNavBar />

      <section className="login-page--form pt-space">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-6 col-xl-5 mx-auto mt-4">
              <div className="shadow rounded-lg p-4 mx-3 my-4">
                <h1 className="text-center font-weight-normal text-xxl">
                  Login
                </h1>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleLogin}
                >
                  {({ handleSubmit, isValid }) => {
                    return (
                      <Form className="pt-4" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <Field
                            component={TextInput}
                            className="form-control form-control-lg font-weight-normal text-base"
                            type="text"
                            name="username"
                            id="username"
                            placeholder="Email Address."
                            appendedComponent={() => (
                              <div className="input-group-text">
                                <span className="fas fa-envelope" />
                              </div>
                            )}
                          />
                        </div>

                        <div className="form-group">
                          <Field
                            component={TextInput}
                            className="form-control form-control-lg font-weight-normal text-base"
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            appendedComponent={() => (
                              <div className="input-group-text">
                                <span className="fas fa-lock" />
                              </div>
                            )}
                          />
                        </div>

                        <div className="form-group d-flex flex-row justify-content-end">
                          <Link
                            to="/auth/forgot-password"
                            className="font-weight-normal"
                          >
                            Forgot Password?
                          </Link>
                        </div>

                        <div className="form-group">
                          <Button
                            type="submit"
                            className="btn-secondary btn-lg btn-block text-lg"
                            title="Login"
                            loadingTitle="Logging in..."
                            loading={state.isLoginLoading}
                            disabled={state.isLoginLoading || !isValid}
                          />
                        </div>
                        <div className="form-group">
                          <div className="col-12 d-flex flex-row justify-content-center">
                            <span className="font-weight-normal text-gray-200">
                              Or login with
                            </span>
                          </div>
                          <GoogleAuthButton title={"Login with Google"} />
                          <FacebookAuthButton title={"Login with Facebook"} />
                        </div>

                        <div className="form-group">
                          <div className="text-center font-weight-normal">
                            New to Triple7events?{" "}
                            <Link
                              to="/auth/sign-up"
                              className="font-weight-bold"
                              href="#"
                            >
                              Sign Up
                            </Link>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Login;
