import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import { socialLoginRequest } from "state/slices/account";
import { getUserProfileRequest } from "state/slices/users";
import { getOneLinerErrorMessage } from "utilities";

export const useSocialAuth = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };
  const dispatch = useDispatch();
  const history = useHistory();

  const socialAuth = async (payload) => {
    setLoading(true);
    const resultAction = await dispatch(socialLoginRequest(payload));
    if (socialLoginRequest.fulfilled.match(resultAction)) {
      if (!resultAction.payload.admin) {
        localStorage.setItem("token", resultAction.payload.token);
        setLoading(false);
        getProfile();
      }
    } else {
      setLoading(false);
      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  const getProfile = async () => {
    setLoading(true);
    const resultAction = await dispatch(getUserProfileRequest());
    if (getUserProfileRequest.fulfilled.match(resultAction)) {
      history.push(from.pathname == "/" ? "/dashboard/booked" : from);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return {
    socialAuth,
    loading,
  };
};
