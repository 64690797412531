import { Icon } from "@iconify/react";
import Button from "components/shared/forms/Button";
import { useSocialAuth } from "hooks/useSocialAuth";
import React, { useCallback } from "react";
import GoogleLogin from "react-google-login";
import "./styles.scss";

export const GoogleAuthButton = ({ title }) => {
  const { socialAuth, loading } = useSocialAuth();

  const responseGoogle = useCallback(({ tokenId }) => {
    socialAuth({
      tokenId,
      flag: "google",
    });
  }, []);

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_KEY}
      onSuccess={responseGoogle}
      onFailure={(res) => console.log(res)}
      cookiePolicy={"single_host_origin"}
      render={({ onClick, disabled }) => (
        <Button
          onClick={onClick}
          disabled={disabled}
          loading={loading}
          type="button"
          className="btn auth-btn btn-google w-100 google-button mt-4"
          icon={() => <Icon icon="flat-color-icons:google" className="mr-2" />}
          title={title}
        />
      )}
    />
  );
};
