import React, { useLayoutEffect, lazy, Suspense} from "react";
import { Provider as StoreProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "state/store";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Login from "components/pages/guest/Login";
import SignUp from "components/pages/guest/SignUp";
import ForgotPassword from "components/pages/guest/ForgotPassword";
import ResetPassword from "components/pages/guest/ResetPasswordPage";
// import HomePage from "components/pages/guest/HomePage";
const EventListPage = lazy(() => import("components/pages/guest/EventListPage"));
import ScrollToTop from "../components/shared/ScrollToTop";
const CategoriesPage = lazy(() => import("components/pages/guest/CategoryListPage"));
const BookingFlowRoutes = lazy(() => import("../components/routes/BookingFlowRoutes"));
import PrivateRoute from "components/shared/PrivateRoute";
const DashboardRoutes = lazy(() => import("components/routes/DashboardRoutes"));
const PrintTicket = lazy(() => import( "components/pages/authenticated/user-dashboard/pages/tickets/print-ticket"));
const EditEventPage = lazy(() => import("components/pages/authenticated/Event-dashboard/pages/editEventPage"));
const CreateEventPage = lazy(() => import("components/pages/authenticated/Event-dashboard/pages/createEventPage"));
const SearchByNameResultPage = lazy(() => import("../components/pages/guest/SearchByNameResultPage"));
const SearchByDateResultPage = lazy(() => import("../components/pages/guest/SearchByDateResultPage"));
// import GetCoordinates from "../components/shared/etc/GetCoordinates";
import Loader from "../components/shared/Loader";
import OfflinePage from "components/pages/guest/_OfflinePage";
const BookingErrorReport = lazy(() => import("components/pages/guest/BookingReport/error"));
const BookingSuccessReport = lazy(() => import("components/pages/guest/BookingReport/success"));
const  PrivacyPolicy = lazy(() => import("Static-pages/privacy-policy"));
const Terms = lazy(() => import("Static-pages/Terms"));
const AboutUs = lazy(() => import("Static-pages/About-us"));
const Faq = lazy(() => import("Static-pages/Faq"));
const HowEventWorks = lazy(() => import("Static-pages/How-event-works"));
const HowStoreWorks = lazy(() => import("Static-pages/How-store-works"));
const HowFuelWorks = lazy(() => import("Static-pages/How-fuel-works"));
const ContactUs = lazy(() => import("Static-pages/Contact-us"));
const PrintOrders = lazy(() => import("components/pages/authenticated/Store-dasboard/pages/Orders/print-orders"));
const PrintWallet = lazy(() => import("components/pages/authenticated/user-dashboard/pages/User-wallet/print-wallet"));
const CustomerStoreRoutes = lazy(() => import("components/routes/CustomerStoreRoutes"));
const HomePage = lazy(() => import('components/pages/guest/HomePage'));

const App = () => {

  function onBeforeLift() {
    store.dispatch
  }
  
  useLayoutEffect(() => {
    document.title = "Buy, Sell, Re-sell Event Tickets, No Booking Fee & No Hidden Charges - Triple7events";
  }, []);
  
  return (
    <>
      <StoreProvider store={store}>
        <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift}>
          <BrowserRouter>
            {/* <GetCoordinates setGettingPosition={setGettingPosition} /> */}
            <ScrollToTop />
            {/* {gettingPosition
              ? (
                  <div className="d-flex justify-content-center align-items-center h-vh">
                  <Loader />
                  </div>
              )
            : ( */}    
              <Suspense fallback={  
                <div className="d-flex justify-content-center align-items-center h-vh">
                  <Loader />
                </div>
                }>
                <Switch>
                  <Route
                    exact
                    path="/ticket/success/:ticketId/:reserveId"
                    component={BookingSuccessReport}
                  />
                  <Route
                    exact
                    path="/ticket/error/:ticketId/:reserveId"
                    component={BookingErrorReport}
                  />
                    <Route path="/offline" component={OfflinePage}/>
                  <Route exact path="/auth/login" component={Login} />
                  <Route exact path="/auth/sign-up" component={SignUp} />
                  <Route exact path="/auth/forgot-password" component={ForgotPassword}/>
                  <Route exact path="/auth/reset-password" component={ResetPassword} />
                  <Route exact path="/" component={HomePage} />
                  <Route exact path="/search-by-name" component={SearchByNameResultPage} />
                  <Route exact path="/search-by-date" component={SearchByDateResultPage} />
                  <Route exact path="/events/:category" component={EventListPage} />
                  <Route path="/event/:id/:eventName" component={BookingFlowRoutes} />
                  <Route exact path="/categories" component={CategoriesPage} />
                  <Route path="/privacy" component={PrivacyPolicy} />
                  <Route path="/terms" component={Terms} />
                  <Route path="/about" component={AboutUs} />
                  <Route path="/faq" component={Faq} />
                  <Route path="/contact" component={ContactUs} />
                  <Route path="/how-event-works" component={HowEventWorks} />
                  <Route path="/how-store-works" component={HowStoreWorks} />
                  <Route path="/how-fuel-works" component={HowFuelWorks} />
                  <PrivateRoute exact path={`/print-ticket/:ticketId`} component={PrintTicket} />
                  <PrivateRoute exact path={`/print-orders`} component={PrintOrders} />
                  <PrivateRoute exact path={`/print-wallet`} component={PrintWallet} />
                  <PrivateRoute exact path='/dashboard/event/:id/:eventName/edit' component={EditEventPage} />
                  <PrivateRoute exact path={`/dashboard/create`} component={CreateEventPage} />
                  <PrivateRoute path="/dashboard" component={DashboardRoutes} />
                  <Route path="/store" component={CustomerStoreRoutes} />
                  <Redirect to="/auth/login" />
                </Switch>
                </Suspense>
              {/* )
            } */}

            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={true}
              newestOnTop={false}
              toastClassName="px-2 shadow-sm rounded-md font-weight-normal"
            />

          </BrowserRouter>
        </PersistGate>
      </StoreProvider>
    </>
  );
};

export default App;
