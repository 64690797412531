import React, { Fragment, useLayoutEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { resetPasswordRequest } from "state/slices/account";
import { Field, Form, Formik } from "formik";
import produce from "immer";
import { toast } from "react-toastify";
import TextInput from "components/shared/forms/TextInput";
import Button from "components/shared/forms/Button";
import AlternateNavBar from "components/shared/partials/AlternateNavBar";
import "./styles.scss";
import { getOneLinerErrorMessage } from "utilities";

const ResetPassword = ({ history }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useLayoutEffect(() => {
    document.title = "Triple7Events - Reset Password";
  }, []);

  const [state, setState] = useState({
    isResetPasswordLoading: false,
  });

  const initialValues = {
    reset_key: "",
    new_pass1: "",
    new_pass2: "",
  };

  const validationSchema = yup.object().shape({
    reset_key: yup.string().label("Reset Key").required(),
    new_pass1: yup
      .string()
      .label("New Password")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/, " ")
      .required(),
    new_pass2: yup
      .string()
      .label("Confirm Password")
      .oneOf([null, yup.ref("new_pass1")], "Both password need to be the same")
      .required(),
  });

  const handleResetPassword = async (formValues, formikHelpers) => {
    setState(
      produce((draft) => {
        draft.isResetPasswordLoading = true;
      })
    );

    const resultAction = await dispatch(resetPasswordRequest(formValues));

    if (resetPasswordRequest.fulfilled.match(resultAction)) {
      setState(
        produce((draft) => {
          draft.isResetPasswordLoading = false;
        })
      );
      toast.success("Password reset successful!");
      history.push("/auth/login");
    } else {
      setState(
        produce((draft) => {
          draft.isResetPasswordLoading = false;
        })
      );
      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <Fragment>
      <AlternateNavBar />

      <section className="reset-password-page--form pt-space">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-6 col-xl-5 mx-auto mt-4">
              <div className="shadow rounded-lg p-4 mx-3 my-4">
                <h3 className="text-center font-weight-normal">
                  Reset Password
                </h3>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleResetPassword}
                >
                  {({ handleSubmit, isValid }) => {
                    return (
                      <Form className="pt-4" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <Field
                            component={TextInput}
                            className="form-control form-control-lg font-weight-normal text-base"
                            type="text"
                            name="reset_key"
                            id="reset_key"
                            placeholder="Enter Reset key"
                            appendedComponent={() => (
                              <div className="input-group-text">
                                <span className="fas fa-key" />
                              </div>
                            )}
                          />
                        </div>
                        <div className="form-group">
                          <Field
                            component={TextInput}
                            className="form-control form-control-lg font-weight-normal text-base"
                            type="password"
                            name="new_pass1"
                            id="new_pass1"
                            placeholder="New Password"
                            appendedComponent={() => (
                              <div className="input-group-text">
                                <span className="fas fa-lock" />
                              </div>
                            )}
                          />{" "}
                          <p className="text-xs font-weight-medium text-accent">
                            Password must be at least 6 characters in length and
                            must contain at least 1 lowercase letter, 1
                            uppercase letter and 1 number
                          </p>
                        </div>
                        <div className="form-group">
                          <Field
                            component={TextInput}
                            className="form-control form-control-lg font-weight-normal text-base"
                            type="password"
                            name="new_pass2"
                            id="new_pass2"
                            placeholder="Confirm Password"
                            appendedComponent={() => (
                              <div className="input-group-text">
                                <span className="fas fa-lock" />
                              </div>
                            )}
                          />
                        </div>

                        <div className="form-group">
                          <Button
                            type="submit"
                            className="btn-secondary btn-lg btn-block text-lg"
                            title="Reset Password"
                            loadingTitle="Resetting Password..."
                            loading={state.isResetPasswordLoading}
                            disabled={state.isResetPasswordLoading || !isValid}
                          />
                        </div>

                        <div className="form-group">
                          <div className="text-center font-weight-normal">
                            New to Triple7events?{" "}
                            <Link
                              to="/auth/sign-up"
                              className="font-weight-bold"
                              href="#"
                            >
                              Sign Up
                            </Link>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ResetPassword;
