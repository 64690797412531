import React, { useCallback } from "react";
import { Icon } from "@iconify/react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Button from "components/shared/forms/Button";
import "./styles.scss";
import { useSocialAuth } from "hooks/useSocialAuth";
import { toast } from "react-toastify";

export const FacebookAuthButton = ({ title }) => {
  const { socialAuth, loading } = useSocialAuth();
  const responseFacebook = useCallback((response) => {
    if (response.accessToken && response.email) {
      // Logged into your webpage and Facebook.
      socialAuth({
        email: response.email,
        tokenId: response.accessToken,
        flag: "facebook",
      });
    } else {
      // The person is not logged into your webpage or we are unable to tell.
      toast.error("An error occurred, please try again.");
    }
  }, []);

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_KEY}
      callback={responseFacebook}
      fields="name,email,picture"
      render={({ onClick, isDisabled, isProcessing }) => (
        <Button
          onClick={onClick}
          disabled={isDisabled}
          loading={isProcessing || loading}
          type="button"
          className="btn auth-btn btn-facebook w-100 google-button mt-3"
          icon={() => <Icon icon="fa:facebook-official" className="mr-2" />}
          title={title}
        />
      )}
    />
  );
};
