import axios from "../api";

export const fetchEventBanners = (data) => {
  return axios.get("/events/banners/", {
    params: data,
  });
};

export const fetchAllCategories = (data) => {
  return axios.get("/events/get_category_list/", {
    params: data,
  });
};

export const fetchAllEventsByName = (data) => {
  return axios.get("/events/search/", {
    params: data,
  });
};

export const fetchAllEvents = (data) => {
  return axios.get("/api/v2.0/event/get_events/", {
    params: data,
  });
};

export const fetchEventByCategory = (data) => {
  return axios.get("/api/v2.0/event/get_events/", {
    params: data,
  });
};

export const fetchEventByCountry = (countryISO3) => {
  return axios.get(`/events/get_city_list/${countryISO3}/`);
};

export const fetchFilteredEvents = (params) => {
  return axios.get(`/api/v2.0/event/get_events/`, { params });
};

export const fetchEventById = (id) => {
  return axios.get(`/events/details/${id}/`);
};

export const fetchT7PerformanceTicketsById = (id) => {
  return axios.get(`/api/v2.0/event/performance/?perfId=${id}`);
};

export const fetchT7PerformanceDeliveryMethodsById = (id) => {
  return axios.get(`/api/v2.0/event/delivery_methods/${id}/`);
};

export const fetchNameSuggestions = (eventName) => {
  return axios.get(`/events_suggestion_list/?event_name=${eventName}`);
};

export const fetchPlaceSuggestions = (eventName) => {
  return axios.get(`/events/placesearch/?name=${eventName}&platform=google`);
};

export const reserveT7ETicket = (data) => {
  return axios.post("/api/v2.0/event/reserve_ticket_t7e/", data);
};

export const reserveT7ETransaction = (data) => {
  return axios.post("/api/v2.0/event/reserve_transaction/", data);
};

export const reserveIGSeatingTicket = (data) => {
  return axios.post("/api/v2.0/event/reserve_seats_ig/", data);
};

export const reserveIGNonSeatingTicket = (data) => {
  return axios.post("/api/v2.0/event/reserve_ticket_ig/", data);
};

export const reserveIGSeatingTransaction = (data) => {
  return axios.post("/api/v2.0/event/reserve_transaction_ig/", data);
};

export const fetchBookedEvents = () => {
  return axios.get("/api/v2.0/event/get_booked_events/");
};

export const fetchSavedEvents = () => {
  return axios.get("/api/v2.0/event/save_event/");
};

export const postPaymentFlutterwave = (eventId, reservationId) => {
  return axios.get(
    `/api/v2.0/event/transaction_success/${eventId}/${reservationId}/`
  );
};

export const preCheckout = (payload) => {
  return axios.post("/api/v2.0/event/pre_checkout/", payload);
};

export const postTransactionProcess = (eventId, reservationId, payload) => {
  return axios.post(
    `/api/v2.0/event/transaction_process/${eventId}/${reservationId}/`,
    payload
  );
};

export const postTransactionSuccess = (
  eventId,
  reservationId,
  txRef,
  gateway
) => {
  if (txRef) {
    return axios.get(
      `/api/v2.0/event/transaction_success/${eventId}/${reservationId}/?txRef=${txRef}&gateway=${gateway}`
    );
  } else {
    return axios.get(
      `/api/v2.0/event/transaction_success/${eventId}/${reservationId}/?device=android`
    );
  }
};

export const fetchMyEvents = () => {
  return axios.get("/api/v2.0/event/my_events/");
};

export const deleteMyEvents = (id) => {
  return axios.delete(`/api/v2.0/event/delete_my_event/${id}/`);
};

export const fetchDashboardCount = () => {
  return axios.get(`/api/v2.0/event/dashboard_counts/`);
};

export const getEventGalleries = (id) => {
  return axios.get(`/api/v2.0/event/gallery/${id}/`);
};

export const uploadGallery = (id, data) => {
  return axios.post(`/api/v2.0/event/post_gallery/${id}/`, data);
};

export const eventDashboard = (id) => {
  return axios.get(`/api/v2.0/event/dash_data/${id}/`);
};

export const eventSummary = (id) => {
  return axios.get(`/api/v2.0/event/summary/${id}/`);
};

export const guestList = (id) => {
  return axios.get(`/api/v2.0/event/event_guests/${id}/`);
};

export const guestListPaginated = (payload) => {
  return axios.get(
    `/api/v2.0/event/event_guests/${payload.eventId}/?page=${payload.page}&perPage=${payload.perPage}`
  );
};

export const createGuest = (id, data) => {
  return axios.post(`/api/event/v2.0/event_guests/${id}/`, data);
};

export const editEventData = (id) => {
  return axios.get(`/api/v2.0/event/edit_event/${id}/`);
};

export const togglePublish = (id) => {
  return axios.patch(`/api/v2.0/event/publish_status/${id}/`);
};

export const getDiscount = (id) => {
  return axios.get(`/api/event/v2.0/event_discounts/${id}/`);
};

export const createDiscount = (id, formPayload) => {
  return axios.post(`/api/event/v2.0/event_discounts/${id}/`, formPayload);
};

export const deleteDiscount = (id, eventId) => {
  return axios.delete(
    `/api/event/v2.0/event_discounts/${eventId}/?discount_id=${id}`
  );
};

export const eventReminder = (id) => {
  return axios.get(`/api/event/v2.0/reminders/${id}/`);
};

export const updateReminder = (id, formPayload) => {
  return axios.post(`/api/event/v1.0/reminders/${id}/`, formPayload);
};

export const getPayouts = (id) => {
  return axios.get(`/api/event/v2.0/event_report/${id}/`);
};

export const getAnalysis = (id) => {
  return axios.get(`/api/v2.0/event/event_analysis/${id}/`);
};

export const getAttendeeLists = (id) => {
  return axios.get(`/api/event/v2.0/attendees_list/${id}/`);
};

export const getAttendeePaginatedLists = (payload) => {
  return axios.get(
    `/api/event/v2.0/attendees_list/${payload.eventId}/?page=${payload.page}&perPage=${payload.perPage}`
  );
};

export const checkInAttendee = (id) => {
  return axios.post(`/api/v2.0/event/checkin/${id}/`);
};

export const addThankYouVideo = (id, formData) => {
  return axios.post(`/api/v2.0/event/thank_you_video/${id}/`, formData);
};

export const watchThankYouVideo = (id) => {
  return axios.get(`/api/v2.0/event/thank_you_video/${id}/`);
};

export const addAffiliate = (id, formData) => {
  return axios.post(`/api/v2.0/event/create_affiliate/${id}/`, formData);
};

export const getQuestion = (id) => {
  return axios.get(`/api/event/v2.0/event_order_form/${id}/`);
};

export const updateQuestion = (id, formPayload) => {
  return axios.put(`/api/event/v2.0/event_order_form/${id}/`, formPayload);
};

export const createQuestion = (id, formPayload) => {
  return axios.post(`/api/event/v2.0/event_order_form/${id}/`, formPayload);
};

export const deleteQuestion = (id) => {
  return axios.delete(`/api/event/v2.0/event_order_form/${id}/`);
};

export const getAnswers = (id) => {
  return axios.get(`/api/v2.0/event/answers/${id}/`);
};

export const getStripeResponse = (id) => {
  return axios.get(`/api/v2.0/account/stripe_connect_response/?code=${id}`);
};

export const getCategoryChoiceList = () => {
  return axios.get(`/api/v2.0/get_category_choice_list/`);
};

export const createEventData = (payload) => {
  return axios.post(`/api/v2.0/event/create_event/`, payload);
};

export const cancelT7EventReservation = (payload) => {
  return axios.put(`/api/v2.0/event/reserve_ticket_t7e/`, payload);
};

export const cancelIGEventReservation = (payload) => {
  return axios.put(`/api/v2.0/event/reserve_ticket_ig/`, payload);
};

export const saveEventMedia = (payload, id) => {
  return axios.post(`/api/v2.0/event/save_event_media/${id}/`, payload);
};

export const sendMail = (payload) => {
  return axios.post(
    `/api/event/v1.0/send_invitation/${payload.eventId}/`,
    payload.formPayload
  );
};

export const addeventContact = (payload, id) => {
  return axios.post(`/api/v2.0/event/event_contacts/${id}/`, payload);
};

export const getEventContacts = (payload) => {
  return axios.get(`/api/v2.0/event/event_contacts/${payload}/`);
};

export const deleteEventContacts = (payload) => {
  return axios.delete(`/api/v2.0/event/event_contacts/${payload}/`);
};
export const saveOtherFiles = (payload, id) => {
  return axios.post(`/api/v2.0/event/upload_additional_photos/${id}/`, payload);
};

export const createDraftEvent = (payload) => {
  return axios.post(`/api/v2.0/event/create_draft/`, payload);
};

export const deleteEventTicket = (id) => {
  return axios.delete(`/api/v2.0/event/delete_ticket/${id}/`);
};

export const getTimeZone = (lat, long) => {
  return axios.get(`/accounts/get_timezone/?lat=${lat}&long=${long}`);
};

export const uploadResaleTicket = (payload) => {
  return axios.post(
    `/api/v2.0/event/resale_ticket_upload/${payload.eventId}/`,
    payload.formData
  );
};

export const getResaleTicket = (payload) => {
  return axios.get(
    `/api/v2.0/event/resale_ticket_upload/${payload.eventId}/?relation_id=${payload.id}`
  );
};

export const deleteResaleTicket = (payload) => {
  return axios.delete(
    `/api/v2.0/event/resale_ticket_details/${payload.eventId}/?tck_upl_id=${payload.id}`
  );
};

export const getResaleRelations = (payload) => {
  return axios.get(`/api/v2.0/event/resale_relations/${payload}/`);
};

export const addEventToSavedEvents = (eventId, isSaved) => {
  if (isSaved) {
    return axios.delete(`/api/v2.0/event/save_event/${eventId}`, {
      event_id: eventId,
    });
  }

  return axios.post(`/api/v2.0/event/save_event/${eventId}`, {
    event_id: eventId,
  });
};

export const addTicketToTrolley = (payload) => {
  return axios.post("/api/v2.0/event/my_trolley_bag/", payload);
};

export const getEventOrders = (payload) => {
  return axios.get(`/api/event/v2.0/event_orders/${payload}/`);
};

export const deleteOtherFile = (payload) => {
  return axios.delete(
    `/api/v1.0/event/upload_additional_photos/${payload.eventId}/?name=${payload.name}&evid=${payload.eventId}`
  );
};

export const updateStepOut = (payload) => {
  return axios.post(
    `/api/v2.0/event/step_out_info/${payload.eventId}/`,
    payload.formPayload
  );
};

export const getStepOut = (payload) => {
  return axios.get(`/api/v2.0/event/step_out_info/${payload}/`);
};

export const postTransactionSuccessWalletPayment = (
  eventId,
  reservationId,
  transactionId
) => {
  return axios.get(
    `/api/v2.0/event/transaction_success/${eventId}/${reservationId}/?transaction_id=${transactionId}`
  );
};

export const VerifyPin = (payload) => {
  return axios.post(`/api/wallets/verify_pin/`, payload);
};

export const contactAdmin = (payload) => {
  return axios.get(`/api/v2.0/stripe_connect_mail/${payload}/`);
};

export const getBookingQuestions = (payload) => {
  return axios.get(`/api/v2.0/event/get_order_form/${payload}/`);
};

export const checkPromo = ({ eventId, payload }) => {
  return axios.post(`/api/v2.0/event/check_promo/${eventId}/`, payload);
};

export const getEventTicketOverview = () => {
  return axios.get(`/events/ticket-transactions/overview/`);
};

export const getEventTicketChart = (data) => {
  return axios.get(`/events/ticket-transactions/chart/`, {
    params: data,
  });
};

export const getEventTransactions = (data) => {
  return axios.get(`/events/ticket-transactions/events/`, {
    params: data,
  });
};

export const getEventTransactionsByID = (data) => {
  return axios.get(`/events/ticket-transactions/`, {
    params: data,
  });
};

export const getEventTicketDetail = (id) => {
  return axios.get(`/events/ticket-transactions/${id}/`);
};
