import React, { Fragment, useLayoutEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { forgotPasswordRequest } from "state/slices/account";
import { Field, Form, Formik } from "formik";
import produce from "immer";
import { toast } from "react-toastify";
import TextInput from "components/shared/forms/TextInput";
import Button from "components/shared/forms/Button";
import AlternateNavBar from "components/shared/partials/AlternateNavBar";
import "./styles.scss";

const ForgotPassword = ({ history }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  useLayoutEffect(() => {
    document.title = "Triple7Events - Forgot Password";
  }, []);

  const [state, setState] = useState({
    isForgotPasswordLoading: false,
  });

  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object().shape({
    email: yup.string().label("Email").required(),
  });

  const handleForgotPassword = async (formValues, formikHelpers) => {
    setState(
      produce((draft) => {
        draft.isForgotPasswordLoading = true;
      })
    );

    const resultAction = await dispatch(forgotPasswordRequest(formValues));
    if (forgotPasswordRequest.fulfilled.match(resultAction)) {
      setState(
        produce((draft) => {
          draft.isForgotPasswordLoading = false;
        })
      );
      toast.success("Recovery code sent on your mail!");
      history.push("/auth/reset-password");
    } else {
      setState(
        produce((draft) => {
          draft.isForgotPasswordLoading = false;
        })
      );

      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <Fragment>
      <AlternateNavBar />

      <section className="forgot-password-page--form pt-space">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-6 col-xl-5 mx-auto mt-4">
              <div className="shadow rounded-lg p-4 mx-3 my-4">
                <h3 className="text-center font-weight-normal">
                  Forgot Password
                </h3>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleForgotPassword}
                >
                  {({ handleSubmit, isValid }) => {
                    return (
                      <Form className="pt-4" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <Field
                            component={TextInput}
                            className="form-control form-control-lg font-weight-normal text-base"
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email"
                            appendedComponent={() => (
                              <div className="input-group-text">
                                <span className="fas fa-envelope" />
                              </div>
                            )}
                          />
                        </div>

                        <div className="form-group">
                          <Button
                            type="submit"
                            className="btn-secondary btn-lg btn-block text-lg"
                            title="Send Recovery Email"
                            loadingTitle="Sending Recovery Email..."
                            loading={state.isForgotPasswordLoading}
                            disabled={state.isForgotPasswordLoading || !isValid}
                          />
                        </div>

                        <div className="form-group">
                          <div className="text-center font-weight-normal">
                            New to Triple7events?{" "}
                            <Link
                              to="/auth/sign-up"
                              className="font-weight-bold"
                              href="#"
                            >
                              Sign Up
                            </Link>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ForgotPassword;
