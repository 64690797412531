import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@iconify/react";
import React from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { effectStoreUserRole } from "utilities";
import "./styles.scss";

export const StoreSidebar = ({ isOpen, toggleSidebar }) => {
  const userRole = JSON.parse(localStorage.getItem("storeUserRole"));
  const history = useHistory();

  const { storeId } = useParams();
  if (!storeId) {
    history.push("/dashboard/stores");
  }
  return (
    <div className="event-dash-sidebar-wrapper">
      <div
        onClick={toggleSidebar}
        className={
          isOpen ? "sidebar-overlay overlay-isOpen" : "sidebar-overlay"
        }
      >
        <div className="closeMenu">
          <Icon icon="ci:close-big" />
        </div>
      </div>
      <div
        id="sidebar-wrapper"
        className={isOpen ? "sidebar-toggle active" : "sidebar-toggle"}
      >
        <div className="sidebar-nav">
          <div className="space-out">
            <div>
              <ul className="pl-0 text-left">
                <li className="bg-gray-70">
                  <NavLink
                    to="/dashboard/stores"
                    className="font-weight-bold pl-3 text-gray-300"
                    onClick={toggleSidebar}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                    Back To Stores
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    exact
                    to={`/dashboard/store/${storeId}`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    Store Dashboard
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/store/${storeId}/edit`}
                    className="pl-3"
                      onClick={toggleSidebar}
                  >
                    Edit Store
                  </NavLink>
                </li> */}

                {effectStoreUserRole(userRole, "category") && (
                  <li>
                    <NavLink
                      activeClassName="active"
                      to={`/dashboard/store/${storeId}/categories`}
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Categories
                    </NavLink>
                  </li>
                )}
                {effectStoreUserRole(userRole, "items") && (
                  <li>
                    <NavLink
                      activeClassName="active"
                      to={`/dashboard/store/${storeId}/items`}
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Products/Items
                    </NavLink>
                  </li>
                )}

                {effectStoreUserRole(userRole, "orders") && (
                  <li>
                    <NavLink
                      activeClassName="active"
                      to={`/dashboard/store/${storeId}/orders`}
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Orders
                    </NavLink>
                  </li>
                )}

                {effectStoreUserRole(userRole, "users") && (
                  <li>
                    <NavLink
                      activeClassName="active"
                      to={`/dashboard/store/${storeId}/userLists`}
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      User Management
                    </NavLink>
                  </li>
                )}

                {effectStoreUserRole(userRole, "sales") && (
                  <li>
                    <NavLink
                      activeClassName="active"
                      to={`/dashboard/store/${storeId}/sales`}
                      className="pl-3"
                      onClick={toggleSidebar}
                    >
                      Sales
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/store/${storeId}/QRcodes`}
                    className="pl-3"
                    onClick={toggleSidebar}
                  >
                    QR Codes
                  </NavLink>
                </li>

                {/* <li>
                  <NavLink
                    activeClassName="active"
                    to={`/dashboard/store/${storeId}/roles`}
                    className="pl-3"
                      onClick={toggleSidebar}
                  >
                    Role Management
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
