//TODO: Replace links with actual url

export const sellTicketsNav = [
  {
    name: "Sell Tickets",
    link: "#",
  },
  {
    name: "Create Event",
    link: "#",
  },
  {
    name: "Resell Tickets",
    link: "#",
  },
];

export const moreNav = [
  {
    name: "How event works",
    link: "how-event-works",
  },
  {
    name: "How store works",
    link: "how-store-works",
  },
  {
    name: "How fuel works",
    link: "how-fuel-works",
  },
  // {
  //   name: "Our Fee",
  //   link: "#ourfee",
  // },
  // {
  //   name: "Ticket Reseller Fee",
  //   link: "#ourfee",
  // },
  // {
  //   name: "Refund and Cancellation",
  //   link: "#refund",
  // },
];

export const accountNav = [
  {
    name: "Dashboard",
    link: "/dashboard/booked",
  },
  {
    name: "My Tickets",
    link: "/dashboard/current-tickets",
  },
  {
    name: "My Events",
    link: "/dashboard/published-events",
  },
  {
    name: "My Account",
    link: "/dashboard/accounts/update-profile",
  },
  {
    name: "My Wallet",
    link: "/dashboard/wallet",
  },
  {
    name: "My Store(s)",
    link: "/dashboard/stores",
  },
];
