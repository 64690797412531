import { combineReducers } from "redux";
import accountReducer from "./slices/account";
import usersReducer from "./slices/users";
import eventsReducer from "./slices/events";
import locationReducer from "./slices/location";
import ticketReducer from "./slices/tickets";
import storeReducer from "./slices/stores";
import fuelReducer from "./slices/fuel";

export default combineReducers({
  account: accountReducer,
  users: usersReducer,
  events: eventsReducer,
  location: locationReducer,
  tickets: ticketReducer,
  stores: storeReducer,
  fuel: fuelReducer,
});
