import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Fuel } from "services/network";

const initialState = {
  storeLoading: false,
  storeError: null,
  data: {},
};

export const getAllFuelBuyersRequest = createAsyncThunk(
  "fuel/allFuelBuyers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.getAllFuelBuyers();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createFuelBuyerRequest = createAsyncThunk(
  "fuel/createFuelBuyer",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.createFuelBuyer(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllFuelVendorsRequest = createAsyncThunk(
  "fuel/allFuelVendors",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.getAllFuelVendors();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createFuelVendorRequest = createAsyncThunk(
  "fuel/createFuelVendor",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.createFuelVendor(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelVendorDashboardRequest = createAsyncThunk(
  "fuel/FuelVendorDash",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.FuelVendorDash(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelVendorProductsRequest = createAsyncThunk(
  "fuel/FuelVendorProduct",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.FuelVendorProducts(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createFuelVendorProductRequest = createAsyncThunk(
  "fuel/createFuelVendorProduct",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.CreateFuelVendorProduct(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editFuelVendorProductRequest = createAsyncThunk(
  "fuel/editFuelVendorProduct",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.EditFuelVendorProduct(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFuelVendorProductRequest = createAsyncThunk(
  "fuel/deleteFuelVendorProduct",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.DeleteFuelVendorProduct(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelVendorStaffsRequest = createAsyncThunk(
  "fuel/FuelVendorStaff",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.FuelVendorStaffs(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createFuelVendorStaffRequest = createAsyncThunk(
  "fuel/createFuelVendorStaff",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.CreateFuelVendorStaff(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editFuelVendorStaffRequest = createAsyncThunk(
  "fuel/editFuelVendorStaff",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.EditFuelVendorStaff(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFuelVendorStaffRequest = createAsyncThunk(
  "fuel/deleteFuelVendorStaff",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.DeleteFuelVendorStaff(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetFuelVendorStaffPinRequest = createAsyncThunk(
  "fuel/resetFuelVendorStaffPin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.ResetFuelVendorStaffPin(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelVendorOrdersRequest = createAsyncThunk(
  "fuel/FuelVendorOrder",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.FuelVendorOrders(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelBuyerOrdersRequest = createAsyncThunk(
  "fuel/FuelBuyerOrder",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.FuelBuyerOrders(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelBuyerOrderDetailsRequest = createAsyncThunk(
  "fuel/FuelBuyerOrderDetail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.FuelBuyerOrderDetails(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelBuyerDashboardRequest = createAsyncThunk(
  "fuel/FuelBuyerDash",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.FuelBuyerDash(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBuyerVehiclesRequest = createAsyncThunk(
  "fuel/BuyerVehicle",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.BuyerVehicles(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createBuyerVehicleRequest = createAsyncThunk(
  "fuel/createBuyerVehicle",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.CreateBuyerVehicle(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editBuyerVehicleRequest = createAsyncThunk(
  "fuel/editBuyerVehicle",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.EditBuyerVehicle(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteBuyerVehicleRequest = createAsyncThunk(
  "fuel/deleteBuyerVehicle",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.DeleteBuyerVehicle(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBuyerDriversRequest = createAsyncThunk(
  "fuel/BuyerDriver",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.BuyerDrivers(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createBuyerDriverRequest = createAsyncThunk(
  "fuel/createBuyerDriver",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.CreateBuyerDriver(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editBuyerDriverRequest = createAsyncThunk(
  "fuel/editBuyerDriver",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.EditBuyerDriver(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteBuyerDriverRequest = createAsyncThunk(
  "fuel/deleteBuyerDriver",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.DeleteBuyerDriver(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const generateCodeRequest = createAsyncThunk(
  "fuel/generateCode",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.GenerateCode(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllStationsRequest = createAsyncThunk(
  "fuel/getAllStations",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.GetAllStations(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendCodeRequest = createAsyncThunk(
  "fuel/SendCode",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.SendCode(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const renewCodeRequest = createAsyncThunk(
  "fuel/RenewCode",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.RenewCode(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const refundRequest = createAsyncThunk(
  "fuel/Refund",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.Refund(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelPayoutTransactionsRequest = createAsyncThunk(
  "fuel/GetFuelPayoutTransactions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.GetFuelPayoutTransactions(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const LinkBankToFLutterWaveRequest = createAsyncThunk(
  "fuel/LinkBankToFLutterWave",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.LinkBankToFLutterWave(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFuelVendorBankRequest = createAsyncThunk(
  "fuel/getFuelVendorBank",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.getFuelVendorBank(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const requestFuelPayoutRequest = createAsyncThunk(
  "fuel/requestFuelPayout",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.requestFuelPayout(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const unlinkFuelAccountRequest = createAsyncThunk(
  "fuel/unlinkFuelAccount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.unlinkFuelAccount(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const verifyCardPaymentRequest = createAsyncThunk(
  "users/verifyCardPayment",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.verifyCardPayment(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const initiateCardPaymentRequest = createAsyncThunk(
  "users/initiateCardPayment",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Fuel.initiateCardPayment(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

const fuelSlice = createSlice({
  name: "fuel",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getFuelVendorDashboardRequest.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
    [getFuelBuyerDashboardRequest.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
  },
});

export const { logoutStoreData } = fuelSlice.actions;
export const { deleteCart } = fuelSlice.actions;
export const { updateReduxCartRequest } = fuelSlice.actions;
export default fuelSlice.reducer;
